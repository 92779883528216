export const getUrlFirstPathname = (win: Window) => {
  const urlPath = win.location.pathname
    .split('/')
    .slice(1)
    .filter((string) => string !== '')
  return urlPath.length > 0 ? urlPath[0] : ''
}

export const getUrlParams = (win: Window) => {
  const urlSearchParams = new URLSearchParams(win.location.search)
  return Object.fromEntries(urlSearchParams.entries())
}
