
import { TopImage } from '@/types/UserState'
import { defineComponent, PropType } from 'vue'
import { Carousel } from 'ant-design-vue'

export default defineComponent({
  name: 'TopScreenCarousel',

  components: {
    Carousel,
  },

  props: {
    topImages: {
      type: Array as PropType<TopImage[]>,
      required: true,
    },
  },
})
