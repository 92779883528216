import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fed0d46c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_TopScreen = _resolveComponent("TopScreen")!
  const _component_DrawingPrize = _resolveComponent("DrawingPrize")!
  const _component_PrizeCoupon = _resolveComponent("PrizeCoupon")!

  return (_ctx.isAppLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppHeader, {
          title: _ctx.userState.title
        }, null, 8, ["title"]),
        (_ctx.isTopScreen)
          ? (_openBlock(), _createBlock(_component_TopScreen, {
              key: 0,
              "top-images": _ctx.userState.topImages,
              description: _ctx.userState.description,
              "main-button-text": _ctx.topScreenMainButtonText,
              "is-loading": _ctx.isLoading,
              "is-disabled-main-button": _ctx.isDisabledTopScreenMainButton,
              onButton: _ctx.pressTopScreenMainButton
            }, null, 8, ["top-images", "description", "main-button-text", "is-loading", "is-disabled-main-button", "onButton"]))
          : _createCommentVNode("", true),
        (_ctx.isDrawingPrize)
          ? (_openBlock(), _createBlock(_component_DrawingPrize, {
              key: 1,
              "has-prize-been-won": _ctx.userState.hasPrizeBeenWon,
              "drawing-animation-url": _ctx.userState.drawingAnimationUrl,
              onNavigate: _ctx.navigateTo
            }, null, 8, ["has-prize-been-won", "drawing-animation-url", "onNavigate"]))
          : _createCommentVNode("", true),
        (_ctx.isPrizeCoupon)
          ? (_openBlock(), _createBlock(_component_PrizeCoupon, {
              key: 2,
              "is-loading": _ctx.isLoading,
              "prize-won-image-url": _ctx.userState.prizeWonImageUrl,
              "prize-coupon-used-at-string": _ctx.prizeCouponUsedAtString,
              "has-used-prize-coupon": _ctx.userState.hasUsedPrizeCoupon,
              onUsePrizeCoupon: _ctx.usePrizeCoupon,
              onNavigate: _ctx.navigateTo
            }, null, 8, ["is-loading", "prize-won-image-url", "prize-coupon-used-at-string", "has-used-prize-coupon", "onUsePrizeCoupon", "onNavigate"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}