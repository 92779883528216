
import { defineComponent } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import { ScreenName } from '@/types/ScreenName'

export default defineComponent({
  name: 'DrawingPrize',

  components: {
    AppButtonPrimary,
    AppButtonSecondary,
  },

  props: {
    hasPrizeBeenWon: {
      type: Boolean,
      required: true,
    },

    drawingAnimationUrl: {
      type: String,
      required: true,
    },
  },

  emits: ['navigate'],

  methods: {
    seePrize() {
      const screenName: ScreenName = 'prize_coupon'
      this.$emit('navigate', screenName)
    },

    goBack() {
      const screenName: ScreenName = 'top_screen'
      this.$emit('navigate', screenName)
    },
  },
})
