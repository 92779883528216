import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0e495bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-screen" }
const _hoisted_2 = { class: "sizing" }
const _hoisted_3 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_AppButtonPrimary = _resolveComponent("AppButtonPrimary")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_Carousel, { "top-images": _ctx.topImages }, null, 8, ["top-images"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1),
      _createVNode(_component_AppButtonPrimary, {
        "is-loading": _ctx.isLoading,
        "is-disabled": _ctx.isDisabledMainButton,
        onButton: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.mainButtonText), 1)
        ]),
        _: 1
      }, 8, ["is-loading", "is-disabled"])
    ])
  ]))
}