
import { defineComponent } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { format, isDate } from 'date-fns'
import { ScreenName } from '@/types/ScreenName'
import { UserState } from '@/types/UserState'
import { getUrlFirstPathname } from '@/helpers/urlProcessing'
import { postSession } from '@/api/dev'
import {
  getUserState,
  patchUserState,
  UserStatePatchAction,
} from '@/api/userState'
import '@/plugins/typekit.js'
import AppHeader from '@/components/AppHeader.vue'
import DrawingPrize from '@/components/DrawingPrize.vue'
import PrizeCoupon from '@/components/PrizeCoupon.vue'
import TopScreen from '@/components/TopScreen.vue'

const initialUserState: UserState = {
  memberNumber: '',
  hasPrizeBeenWon: false,
  hasUsedPrizeCoupon: false,
  prizeCouponUsedAt: null,
  lotteryId: '',
  tenantId: '',
  title: '',
  description: '',
  drawButtonText: '',
  topImages: [],
  drawingAnimationUrl: '',
  prizeWonImageUrl: '',
  areTherePrizesLeft: false,
  isWithinOperatingPeriod: false,
}
const initialScreenName: ScreenName = 'top_screen'

const DATE_FORMAT = 'yyyy/MM/dd HH:mm'

export default defineComponent({
  name: 'App',

  components: {
    AppHeader,
    DrawingPrize,
    PrizeCoupon,
    TopScreen,
  },

  data() {
    return {
      nodeEnvironment: 'development',
      isAppStarted: false,
      isLoading: false,
      currentComponentName: cloneDeep(initialScreenName as ScreenName),
      userState: cloneDeep(initialUserState),
    }
  },

  computed: {
    isDevelopmentEnvironment(): boolean {
      return this.nodeEnvironment === 'development'
    },

    isAppLoaded(): boolean {
      return this.userState.memberNumber !== '' && this.isAppStarted
    },

    isTopScreen(): boolean {
      return this.currentComponentName === 'top_screen'
    },

    isDrawingPrize(): boolean {
      return this.currentComponentName === 'drawing_prize'
    },

    isPrizeCoupon(): boolean {
      return this.currentComponentName === 'prize_coupon'
    },

    topScreenMainButtonText(): string {
      const {
        areTherePrizesLeft,
        hasPrizeBeenWon,
        drawButtonText,
        isWithinOperatingPeriod,
      } = this.userState
      if (hasPrizeBeenWon) {
        return '結果を見る'
      } else if (!isWithinOperatingPeriod) {
        return '期間外です'
      } else if (!areTherePrizesLeft) {
        return '終了しました'
      } else {
        return drawButtonText
      }
    },

    isDisabledTopScreenMainButton(): boolean {
      const { areTherePrizesLeft, hasPrizeBeenWon, isWithinOperatingPeriod } =
        this.userState
      return (
        !hasPrizeBeenWon && (!areTherePrizesLeft || !isWithinOperatingPeriod)
      )
    },

    prizeCouponUsedAtString(): string {
      const prizeCouponUsedAt = this.userState.prizeCouponUsedAt as Date | null
      return prizeCouponUsedAt && isDate(prizeCouponUsedAt)
        ? `受取時間 ${format(prizeCouponUsedAt, DATE_FORMAT)}`
        : ''
    },
  },

  async created() {
    this.nodeEnvironment = process.env.NODE_ENV || 'development'
    if (this.isDevelopmentEnvironment) {
      this.checkForDevUrlAndPostSession()
    }
    setTimeout(() => {
      this.startApp()
    }, 50)
  },

  mounted() {
    this.downloadFont()
  },

  methods: {
    async checkForDevUrlAndPostSession() {
      let isDevPostSession = false
      if (getUrlFirstPathname(window) === 'dev') {
        isDevPostSession = true
      }
      if (!isDevPostSession) {
        return
      }
      await postSession()
    },

    startApp() {
      this.isAppStarted = true
      this.getUserState()
    },

    downloadFont() {
      var script = document.createElement('script')
      script.setAttribute('src', 'plugins/typekit.js')
      document.head.appendChild
    },

    navigateTo(screenName: ScreenName) {
      this.currentComponentName = screenName
      window.scrollTo(0, 0)
    },

    pressTopScreenMainButton() {
      const { areTherePrizesLeft, hasPrizeBeenWon, isWithinOperatingPeriod } =
        this.userState
      if (hasPrizeBeenWon) {
        this.navigateTo('prize_coupon')
      } else if (areTherePrizesLeft && isWithinOperatingPeriod) {
        this.patchUserState('draw_prize')
        this.navigateTo('drawing_prize')
      }
    },

    seePrize() {
      this.navigateTo('prize_coupon')
    },

    usePrizeCoupon() {
      this.patchUserState('use_prize_coupon')
    },

    async getUserState() {
      this.isLoading = true
      let data
      try {
        data = await getUserState()
      } catch {
        this.isLoading = false
        this.isAppStarted = false
        return
      }
      this.isLoading = false
      if (!data) {
        return
      }
      this.userState = data
    },

    async patchUserState(action: UserStatePatchAction) {
      this.isLoading = true
      let data
      try {
        data = await patchUserState(action)
      } catch {
        this.isLoading = false
        this.getUserState()
        this.navigateTo('top_screen')
        return
      }
      this.isLoading = false
      if (!data) {
        return
      }
      this.userState = data
    },
  },
})
