import { UserState } from '@/types/UserState'
import { axios } from './axios'

const prepareData = (data: UserState): UserState => {
  const { prizeCouponUsedAt } = data
  return {
    ...data,
    prizeCouponUsedAt: new Date(prizeCouponUsedAt as unknown as string),
  }
}

export const getUserState = async (): Promise<UserState | undefined> => {
  try {
    const { data } = await axios.get<UserState>(
      `${process.env.VUE_APP_USER_STATES_API}/_`
    )
    return prepareData(data)
  } catch (error) {
    console.error('getUserState', error)
    throw error
  }
}

const userStatePatchActions = ['draw_prize', 'use_prize_coupon'] as const
export type UserStatePatchAction = typeof userStatePatchActions[number]

interface UserStatePatchParams {
  action: UserStatePatchAction
}

export const patchUserState = async (
  action: UserStatePatchAction
): Promise<UserState | undefined> => {
  const params: UserStatePatchParams = {
    action,
  }
  try {
    const { data } = await axios.patch<UserState>(
      `${process.env.VUE_APP_USER_STATES_API}/_`,
      params
    )
    return prepareData(data)
  } catch (error) {
    console.error('patchUserState', error)
    throw error
  }
}
