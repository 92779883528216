import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    loading: _ctx.isLoading,
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["loading", "disabled"]))
}