
import { defineComponent } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import AppButtonSecondary from '@/components/AppButtonSecondary.vue'
import NotesOnUse from '@/components/prize-coupon/PrizeCouponNotesOnUse.vue'
import PrizeWonImage from '@/components/prize-coupon/PrizeCouponPrizeWonImage.vue'
import StepANoticeInstructionsBeforeUse from '@/components/prize-coupon/PrizeCouponStepANoticeInstructionsBeforeUse.vue'
import StepBNoticeWithConfirmUse from '@/components/prize-coupon/PrizeCouponStepBNoticeWithConfirmUse.vue'
import { ScreenName } from '@/types/ScreenName'

const couponSteps = [
  'coupon_step_a_waiting_on_coupon_use',
  'coupon_step_b_waiting_on_press_use_button',
  'coupon_step_c_coupon_used',
] as const
type CouponStep = typeof couponSteps[number]

export default defineComponent({
  name: 'PrizeCoupon',

  components: {
    AppButtonPrimary,
    AppButtonSecondary,
    NotesOnUse,
    PrizeWonImage,
    StepANoticeInstructionsBeforeUse,
    StepBNoticeWithConfirmUse,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },

    prizeWonImageUrl: {
      type: String,
      required: true,
    },

    hasUsedPrizeCoupon: {
      type: Boolean,
      required: true,
    },

    prizeCouponUsedAtString: {
      type: String,
      default: '',
    },
  },

  emits: ['usePrizeCoupon', 'navigate'],

  data() {
    return {
      currentStepIndex: 0,
      isConfirmCheckboxChecked: false,
    }
  },

  computed: {
    currentStepName(): CouponStep {
      return couponSteps[this.currentStepIndex]
    },

    isCouponStepAWaitingOnCouponUse() {
      return this.currentStepName === 'coupon_step_a_waiting_on_coupon_use'
    },

    isCouponStepBWaitingOnPressUseButton() {
      return (
        this.currentStepName === 'coupon_step_b_waiting_on_press_use_button'
      )
    },

    isCouponStepCCouponUsed() {
      return this.currentStepName === 'coupon_step_c_coupon_used'
    },

    isDisabledButton() {
      return (
        !this.isConfirmCheckboxChecked &&
        this.isCouponStepBWaitingOnPressUseButton
      )
    },

    showButton() {
      return !this.isCouponStepCCouponUsed
    },

    buttonText() {
      return this.isCouponStepAWaitingOnCouponUse
        ? '賞品を受け取る'
        : '賞品を受取済にする'
    },
  },

  watch: {
    hasUsedPrizeCoupon() {
      this.currentStepIndex = 2
    },
  },

  mounted() {
    if (this.hasUsedPrizeCoupon) {
      this.currentStepIndex = 2
    }
  },

  methods: {
    clickUseButton() {
      if (this.isCouponStepAWaitingOnCouponUse) {
        this.currentStepIndex++
      } else if (
        this.isCouponStepBWaitingOnPressUseButton &&
        this.isConfirmCheckboxChecked
      ) {
        this.$emit('usePrizeCoupon')
      }
    },

    toggleConfirmCheckbox() {
      this.isConfirmCheckboxChecked = !this.isConfirmCheckboxChecked
    },

    goBack() {
      const screenName: ScreenName = 'top_screen'
      this.$emit('navigate', screenName)
    },
  },
})
