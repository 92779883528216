
import { defineComponent } from 'vue'
import { Button } from 'ant-design-vue'
import { RightOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AppButtonPrimary',

  components: {
    Button,
    RightOutlined,
  },

  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    showCaret: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['button'],
})
