import { axios } from './axios'

interface SessionPostParams {
  item: string
}

export const postSession = async (): Promise<void> => {
  const item = `${process.env.VUE_APP_DEV_SESSIONS_API_ITEM}`
  const params: SessionPostParams = { item }
  try {
    await axios.post(`${process.env.VUE_APP_DEV_SESSIONS_API}`, params)
  } catch (error) {
    console.error('consoleLog', error)
    throw error
  }
}
