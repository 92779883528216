
import { TopImage } from '@/types/UserState'
import { defineComponent, PropType } from 'vue'
import AppButtonPrimary from '@/components/AppButtonPrimary.vue'
import Carousel from '@/components/top-screen/TopScreenCarousel.vue'

export default defineComponent({
  name: 'TopScreen',

  components: {
    AppButtonPrimary,
    Carousel,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },

    topImages: {
      type: Array as PropType<TopImage[]>,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    mainButtonText: {
      type: String,
      required: true,
    },

    isDisabledMainButton: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['button'],
})
