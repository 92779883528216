
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppHeader',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
