
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PrizeCouponPrizeWonImage',

  props: {
    prizeWonImageUrl: {
      type: String,
      required: true,
    },

    hasUsedPrizeCoupon: {
      type: Boolean,
      required: true,
    },

    prizeCouponUsedAtString: {
      type: String,
      default: '',
    },
  },
})
